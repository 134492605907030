export default class {
    constructor(pageActionWithSubActionsElement) {
        this._rootElement = pageActionWithSubActionsElement;
    }
    expand() {
        this._rootElement.classList.add('expanded');
    }
    collapse() {
        this._rootElement.classList.remove('expanded');
    }
    isExpanded() {
        return this._rootElement.classList.contains('expanded');
    }
}
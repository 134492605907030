import PageActionWithSubActions from './PageActionWithSubActions';

export default class {
    constructor() {
        this._pageActionsWithSubActions = new Map();
    }
    bindEvents() {
        document.body.addEventListener('click', (event) => {
            if (event.target.closest('.page-action-show-sub-actions') !== null) {
                event.preventDefault();
            }

            const clickedPageActionWithSubActionsElement = event.target.closest('.page-action-with-sub-actions');
            if (clickedPageActionWithSubActionsElement === null) {
                this._collapseExpandedPageActions();
                return;
            }

            const pageActionWithSubActions = this._getOrCreatePageActionWithSubActions(clickedPageActionWithSubActionsElement);
            if (! pageActionWithSubActions.isExpanded()) {
                pageActionWithSubActions.expand();
            } else {
                pageActionWithSubActions.collapse();
            }
        });
    }
    _collapseExpandedPageActions() {
        this._pageActionsWithSubActions.forEach((pageAction) => {
            pageAction.collapse();
        });
    }
    _getOrCreatePageActionWithSubActions(pageActionWithSubActionsElement) {
        if (! this._pageActionsWithSubActions.has(pageActionWithSubActionsElement)) {
            this._pageActionsWithSubActions.set(pageActionWithSubActionsElement, new PageActionWithSubActions(pageActionWithSubActionsElement));
        }
        return this._pageActionsWithSubActions.get(pageActionWithSubActionsElement);
    }
}
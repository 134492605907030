import Bugsnag from "@bugsnag/js";
import getScriptParams from "modules/getScriptParams";
import markupDrivenBehavior from "modules/markup-driven-behavior";
import { liveBindTooltips } from "modules/TooltipManager";
import { overrideNativeAlert, overrideNativeConfirm } from "modules/alert";
import PageActionWithSubActionsManager from "./_shared/PageActionWithSubActions/PageActionWithSubActionsManager";
import Vue from "vue";
window.Vue = window.Vue || Vue;

const bundleName = "fulfilment-portal";

overrideNativeAlert();
overrideNativeConfirm();

document.addEventListener("DOMContentLoaded", () => {
    const params = getScriptParams(bundleName);

    window.picqer = window.picqer || {};
    window.picqer = Object.assign({}, window.picqer, {
        assetsPrefix: params.assetsPrefix,
    });

    if (params.bugsnagKey && params.bugsnagKey.length > 0 && params.version && params.version.length > 0) {
        Bugsnag.start({
            apiKey: params.bugsnagKey,
            appVersion: params.version,
            collectUserIp: false,
            autoTrackSessions: false,
        });
    }

    new PageActionWithSubActionsManager().bindEvents();
    liveBindTooltips();

    document.addEventListener("click", markupDrivenBehavior.onClick);
    document.addEventListener("submit", markupDrivenBehavior.onSubmit);
});
